<template>
  <div
    class="modal fade"
    id="sendmessagetoclient"
    tabindex="-1"
    role="dialog"
    aria-labelledby="contact"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content d-flex align-items-center" v-if="success">
        <successMessage :message="success" />
        <button @click="hideModal" class="__close__success__modal">
          Close
        </button>
      </div>
      <div class="modal-content pb-3" v-if="!success">
        <div class="modal-header pt-4 pb-2">
          <h5 class="modal-title" id="exampleModalLabel">
            Create Notification
          </h5>
        </div>
        <div class="modal-body m-0">
          <div class="row justify-content-center">
            <div
              class="col-md-12"
              v-if="payload.type !== 'publish' || isEditing">
              <el-form
                :model="formData"
                :rules="rules"
                ref="ruleForm"
                @submit.prevent.native="checkEnter"
                labelPosition="top">
                <div class="row justify-content-center">
                  <div class="col-md-12 form_input_container">
                    <el-form-item label="Type" prop="status">
                      <el-select
                        style="width: 100%"
                        v-model="formData.status"
                        filterable>
                        <el-option
                          label="Notification Strip"
                          value="0"></el-option>
                        <el-option
                          v-if="!disallowedUsers"
                          label="Birthday Message"
                          value="2"></el-option>
                        <el-option
                          v-if="!disallowedUsers"
                          label="Anniversary Message"
                          value="3"></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div
                    class="col-md-12 form_input_container"
                    v-if="formData.status == 0 || formData.status == 1">
                    <el-form-item label="End Date" prop="end_date">
                      <el-date-picker
                        v-model="formData.end_date"
                        type="date"
                        style="width: 100%; height: 50px !important"
                        placeholder="Select end date"
                        value-format="yyyy-MM-dd">
                      </el-date-picker>
                    </el-form-item>
                  </div>
                  <div
                    class="col-md-12 form_input_container"
                    v-if="formData.status == 0 || formData.status == 1">
                    <el-form-item label="Country" prop="Country">
                      <el-select
                        v-model="formData.country"
                        filterable
                        placeholder="Select country"
                        style="width: 100%">
                        <el-option
                          v-for="(country, index) in availableCountries"
                          :key="index"
                          :label="country.label"
                          :value="country.value"></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div class="col-md-12">
                    <div class="form_input_container">
                      <el-form-item label="Message" prop="message">
                        <el-input
                          type="textarea"
                          rows="8"
                          autocomplete="off"
                          placeholder="Enter message to display on notification strip"
                          v-model="formData.message"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                </div>
              </el-form>
            </div>
            <div class="col-md-12" v-else>
              <p class="__message_text">
                {{ payload.data.row.message }}
              </p>
            </div>
          </div>
          <div class="row justify-content-center mb-4">
            <div
              class="col-md-12 modal__footer__btns d-flex justify-content-between">
              <button style="width: 123px" class="cancel" @click="hideModal">
                Cancel
              </button>

              <el-button
                type="danger"
                v-if="payload.type == 'new'"
                :loading="loadSaving"
                style="width: 123px"
                class="cancel"
                @click="submitAction('ruleForm', 'save')"
                >Save</el-button
              >
              <el-button
                v-if="payload.type == 'new' && formData.status == 0"
                type="primary"
                :loading="loadingPublish"
                id="cancelinput"
                @click="submitAction('ruleForm', 'publish')">
                Save & Publish
              </el-button>
              <button
                v-if="payload.type == 'publish' && !isEditing"
                style="width: 123px"
                class="cancel"
                @click="edit">
                Edit
              </button>
              <el-button
                v-if="payload.type == 'publish' && !isEditing"
                style=""
                type="primary"
                :loading="loadSaving"
                id="cancelinput"
                @click="publish">
                Publish
              </el-button>
              <el-button
                v-if="payload.type == 'publish' && isEditing"
                style=""
                type="primary"
                :loading="loadingPublish"
                id="cancelinput"
                @click="submitAction('ruleForm', 'edit')">
                Edit
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import store from "@/state/store.js";
  import Storage from "../../../services/storage";
  var storage = new Storage();
  import $ from "jquery";
  import industries from "@/helpers/adminMessageGroups.js";
  export default {
    props: {
      payload: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        success: "",
        error: "",
        isEditing: false,
        loading: false,
        loadSaving: false,
        loadingPublish: false,
        formData: {
          end_date: "",
          message: "",
          status: "",
          country: "",
        },
        rules: {
          message: [
            {
              required: true,
              message: "Please Enter your message.",
              trigger: "change",
            },
          ],
          end_date: [
            {
              required: true,
              message: "Please select end date.",
              trigger: "change",
            },
          ],
          country: [
            {
              required: true,
              message: "Please select country.",
              trigger: "change",
            },
          ],
        },
        countries: [
          { value: "GH", label: "Ghana (GH)" },
          { value: "CI", label: "Cote D'Ivoire (CI)" },
          { value: "SN", label: "Senegal (SN)" },
        ],
      };
    },
    computed: {
      industries() {
        return industries;
      },
      findNumberOfRecipient() {
        let { message } = this.formData;
        if (message.length == 0) {
          return 0;
        }
        if (message.length <= 160) {
          this.formData.perMessage = 1;
          return 1;
        } else if (message.length > 160 && message.length < 320) {
          this.formData.perMessage = 2;
          return 2;
        } else if (message.length > 320 && message.length < 460) {
          this.formData.perMessage = 3;
          return 3;
        }
      },
      disallowedUsers() {
        return this.$store.getters["auth/disallowedUsers"];
      },
      availableCountries() {
        return this.countries.filter((country) => {
          // if disallowed user show only the country of the user
          if (this.disallowedUsers) {
            return (
              country.value === storage.getSavedState("auth.admin").country
            );
          }
          return true;
        });
      },
    },
    methods: {
      edit() {
        const { end_date, message, country } = this.payload.data.row;
        this.formData = {
          end_date: end_date,
          message: message,
          country: country,
        };
        this.isEditing = true;
      },
      publish() {
        this.loadSaving = true;
        let payLoad = { notification_id: this.payload.data.row.id };
        return store
          .dispatch("admin/notifications/publishNotification", payLoad)
          .then((message) => {
            this.success = message;
          })
          .catch((err) => {
            if (err?.response?.data == undefined) {
              this.errors = "Network error try again.";
            }
          })
          .finally(() => (this.loadSaving = false));
      },

      Unpublish() {
        this.loadSaving = true;
        let payLoad = { notification_id: this.payload.data.row.id };
        return store
          .dispatch("admin/notifications/UnpublishNotification", payLoad)
          .then((message) => {
            this.success = message;
          })
          .catch((err) => {
            if (err?.response?.data == undefined) {
              this.errors = "Network error try again.";
            }
          })
          .finally(() => (this.loadSaving = false));
      },
      hideModal() {
        $("#sendmessagetoclient").modal("hide");
        this.error = "";
        this.success = "";
        this.loading = false;
        this.loadSaving = false;
        this.loadingPublish = false;
        this.formData = {
          end_date: "",
          message: "",
          status: "",
          country: "",
        };
        this.$emit("close");
      },
      save() {
        this.loadSaving = true;
        return store
          .dispatch("admin/notifications/saveNotification", this.formData)
          .then((message) => {
            this.success = message;
          })
          .catch((err) => {
            if (err?.response?.data == undefined) {
              this.errors = "Network error try again.";
            }
          })
          .finally(() => (this.loadSaving = false));
      },
      saveAndPublic() {
        this.loadingPublish = true;
        this.formData.status = 1;
        return store
          .dispatch("admin/notifications/saveNotification", this.formData)
          .then((message) => {
            this.success = message;
          })
          .catch((err) => {
            if (err?.response?.data == undefined) {
              this.errors = "Network error try again.";
            }
          })
          .finally(() => (this.loadingPublish = false));
      },
      editNotification() {
        this.loadingPublish = true;
        this.formData.id = this.payload.data.row.id;
        const stateData = this.payload.data.row;
        stateData.message = this.formData.message;
        stateData.end_date = this.formData.end_date;
        stateData.index = this.payload.data.$index;
        this.formData.stateData = stateData;
        return store
          .dispatch("admin/notifications/editNotification", this.formData)
          .then((message) => {
            this.success = message;
          })
          .catch((err) => {
            if (err?.response?.data == undefined) {
              this.errors = "Network error try again.";
            }
          })
          .finally(() => (this.loadingPublish = false));
      },
      submitAction(formName, type) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (type == "save") {
              this.save();
            }
            if (type == "publish") {
              return this.saveAndPublic();
            }
            if (type == "edit") {
              return this.editNotification();
            }
          } else {
            this.loading = false;
            return false;
          }
        });
      },
    },
    mounted() {
      this.error = "";
      this.success = "";
      this.loading = false;
      this.isEditing = false;
      this.formData = {
        end_date: "",
        message: "",
        status: "",
        country: "",
      };
      //   store.dispatch('admin/massMessage/getIndustries').then( data => console.info(data))
    },
  };
</script>
<style scoped>
  .text_description {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: #484a4f;
  }

  .__message_text {
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #8a8c8f;
    padding: 1em 0;
  }
</style>
