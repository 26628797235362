<template>
  <div class="__main_card_container">
    <div class="_main_card_header">
      <div class="__header_inner admin_header_padding">
        <h2>
          {{ $route.name }}
        </h2>
        <div class="side_nav_wrapper">
          <div class="side_nav_wrapper">
            <div class="__side_nav">
              <button
                class="btn"
                :class="[
                  $route.path == '/admin/notifications'
                    ? '__side_item_active'
                    : '',
                ]"
                @click="navigate('/admin/notifications')">
                Notifications
              </button>
              <button
                v-if="!disallowedUsers"
                class="btn"
                :class="[
                  $route.path == '/admin/birthdays-and-anniversaries'
                    ? '__side_item_active'
                    : '',
                ]"
                @click="navigate('/admin/birthdays-and-anniversaries')">
                Birthdays/Anniversary
              </button>
              <button
                v-if="!disallowedUsers"
                class="btn"
                :class="[
                  $route.path == '/admin/feedbacks' ? '__side_item_active' : '',
                ]"
                @click="navigate('/admin/feedbacks')">
                Feedbacks
              </button>
              <!-- <button class="btn" :class="[$route.path  == '/admin/categories' ? '__side_item_active' : '']" @click="navigate('/admin/categories')">
                                    Categories
                                </button> -->
            </div>
          </div>
          <div
            class="__side_nav mb-3"
            v-if="
              $route.path == '/admin/notifications' ||
              $route.path == '/admin/birthdays-and-anniversaries'
            ">
            <el-button
              type="primary"
              class="create_notificaton"
              @click="openModal">
              Create
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="main_card_body">
      <router-view> </router-view>
    </div>
    <NotificationsModal
      ref="notificationModal"
      :payload="payload"
      @close="closeNotifcationModal" />
  </div>
</template>

<script>
  import $ from "jquery";
  import NotificationsModal from "./NoficationModal";
  export default {
    name: "admin-notifications",
    components: {
      NotificationsModal,
    },
    data() {
      return {
        loading: false,
        payload: {
          type: "",
        },
      };
    },
    methods: {
      navigate(route) {
        if (this.$route.path == route) return;
        this.$router.push(route).catch((err) => {});
      },
      openNotificationModal() {
        let element = this.$refs.notificationModal.$el;
        $(element).modal("show");
      },
      openModal() {
        this.payload = {
          type: "new",
        };
        this.openNotificationModal();
      },
      closeNotifcationModal() {
        let element = this.$refs.notificationModal.$el;
        $(element).modal("hide");
      },
    },
    computed: {
      disallowedUsers() {
        return this.$store.getters["auth/disallowedUsers"];
      },
    },
  };
</script>

<style scoped>
  .create_notificaton {
    color: #ffffff;
    background: #f7921c;
  }
</style>
