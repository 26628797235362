var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"__main_card_container"},[_c('div',{staticClass:"_main_card_header"},[_c('div',{staticClass:"__header_inner admin_header_padding"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$route.name)+" ")]),_c('div',{staticClass:"side_nav_wrapper"},[_c('div',{staticClass:"side_nav_wrapper"},[_c('div',{staticClass:"__side_nav"},[_c('button',{staticClass:"btn",class:[
                _vm.$route.path == '/admin/notifications'
                  ? '__side_item_active'
                  : '',
              ],on:{"click":function($event){return _vm.navigate('/admin/notifications')}}},[_vm._v(" Notifications ")]),(!_vm.disallowedUsers)?_c('button',{staticClass:"btn",class:[
                _vm.$route.path == '/admin/birthdays-and-anniversaries'
                  ? '__side_item_active'
                  : '',
              ],on:{"click":function($event){return _vm.navigate('/admin/birthdays-and-anniversaries')}}},[_vm._v(" Birthdays/Anniversary ")]):_vm._e(),(!_vm.disallowedUsers)?_c('button',{staticClass:"btn",class:[
                _vm.$route.path == '/admin/feedbacks' ? '__side_item_active' : '',
              ],on:{"click":function($event){return _vm.navigate('/admin/feedbacks')}}},[_vm._v(" Feedbacks ")]):_vm._e()])]),(
            _vm.$route.path == '/admin/notifications' ||
            _vm.$route.path == '/admin/birthdays-and-anniversaries'
          )?_c('div',{staticClass:"__side_nav mb-3"},[_c('el-button',{staticClass:"create_notificaton",attrs:{"type":"primary"},on:{"click":_vm.openModal}},[_vm._v(" Create ")])],1):_vm._e()])])]),_c('div',{staticClass:"main_card_body"},[_c('router-view')],1),_c('NotificationsModal',{ref:"notificationModal",attrs:{"payload":_vm.payload},on:{"close":_vm.closeNotifcationModal}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }